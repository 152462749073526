<template>
  <phi-page
    class="page-id"
    color="transparent"
    :header-image="card.cover"
    :style="{backgroundColor: card.color ? card.color : null, backgroundImage: card.cover ? 'none' : null}"
  >
    <div
      slot="toolbar"
      class="page-toolbar"
    >
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back" 
      />
    </div>

    <div
      slot="header"
      class="page-header"
    >
       
    </div>    

    <div class="details">

      <div class="toolbar">
        <h1 v-text="$store.getters.getSetting('title', 'Colegio Aureliano Buendía')" :style="titleStyle"></h1>
      </div> 

      <div class="avatar">
        <img :src="card.avatar ? card.avatar : app.user.avatar" />
      </div>  
            
      <div v-if="getadvanceQR" >

        <div class="name" v-if="advance.name">
          <h1 style="font-size: 1.6em">{{advance.name}}</h1>
        </div>

        <ul class="fields">
          <li v-if="course" class="course" >
            <span>{{ course }}</span>
          </li>

          <li v-if="advance.code" class="document" >
            <label>{{ $t('noun.studentCode') }}</label>
            <span> {{ advance.code }} </span>
          </li>
        </ul>

        <ul class="fields">
          <li v-if="advance.document" class="document" >
            <label>{{ advance.documentType }}</label>
            <span> {{ advance.document }} </span>
          </li> 

          <li v-if="advance.mobile" class="document" >
            <label>{{ $t('noun.mobile') }} </label>
            <span> {{ advance.mobile }} </span>
          </li>

          <li class="document" v-if="advance.birthDay">
            <label>{{ $t('noun.birthday') }} </label>
            <span> {{ advance.birthDay }} </span>
          </li>
        </ul>  

        <ul class="fields">
          <li class="document" v-if="advance.blood">
            <label>{{ $t('clinic.blood_type') }} </label>
            <span> {{ advance.blood }} </span>
          </li>

          <li class="document" v-if="advance.weight">
            <label>{{ $t('clinic.weight') }} </label>
            <span> {{ advance.weight }} kg</span>
          </li>

          <li class="document" v-if="advance.height">
            <label>{{ $t('clinic.height') }} </label>
            <span> {{ advance.height }} cm</span>
          </li>
        </ul>  
        
        <ul class="fields">
          <li class="document" v-if="advance.role">
            <span style="text-transform: uppercase;">{{ $t(advance.role) }}</span>
          </li>
        </ul>
      </div>

      <div v-else>

        <div class="name">
          <h1 style="font-size: 1.6em">{{app.user.firstName}} {{app.user.lastName}}</h1>
        </div>

        <ul class="fields">
          <li
            v-if="course"
            class="course"
          >
            <span>{{ course }}</span>
          </li>
          <li
            v-if="card.student && card.student.code"
            class="document"
          >
            <label>{{ $t('noun.studentCode') }}</label>
            <span>{{ card.student.code }}</span>
          </li>
          <li
            v-else
            class="document"
          >
            <label>{{ card.documentType }}</label>
            <span>{{ card.document }}</span>
          </li>        
        </ul>

        <ul class="fields" v-if="card.student">
          <li class="document" >
            <label>{{ card.documentType }} </label>
            <span>{{ card.document }}</span>
          </li>

          <li class="document" >
            <label>{{ $t('noun.mobile') }} </label>
            <span>{{ card.mobile }}</span>
          </li>

          <li class="document" >
            <label>{{ $t('noun.birthday') }} </label>
            <span>{{ format_date(card.birthDay) }}</span>
          </li>
        </ul>

        <ul class="fields" v-if="clinicFields">
          <li          
            class="document"
          >
            <label>{{ $t('clinic.blood_type') }} </label>
            <span>{{ card.clinic? card.clinic.blood:"" }}</span>
          </li>

          <li          
            class="document"
          >
            <label>{{ $t('clinic.weight') }} </label>
            <span>{{ card.clinic? card.clinic.weight:"" }} kg</span>
          </li>

          <li          
            class="document"
          >
            <label>{{ $t('clinic.height') }} </label>
            <span>{{ card.clinic? card.clinic.height:"" }} cm</span>
          </li>

          <li          
            class="document"
          >
            <label>{{ $t('clinic.medical_insurance_EPS') }} </label>
            <span>{{ card.clinic? card.clinic.field4:"" }}</span>
          </li>
        </ul>
      </div>

      <div class="qr" v-if="enableQrdata" >
        <qrc-generator
          :size="150"
          :value="qrdata"
        ></qrc-generator>
      </div>
    </div>

    <div class="roles" v-if="card.roles && !getadvanceQR" >
      <div
        v-for="(role,i) in card.roles"
        :key="i"
        class="role"
      >
        <template v-if="role.role">
          <label>{{ $t(role.role.singular) }}</label>
          <ul>
            <li
              v-for="(node,j) in role.nodes"
              :key="j"
            >{{ node.name }}</li>
          </ul>
        </template>

        <template v-if="role.relation">
          <label>{{ $t(role.relation) }}</label>
          <ul>
            <li
              v-for="(relative,k) in role.relatives"
              :key="k"
            >
              <span>{{ relative.person.firstname }} {{ relative.person.lastname }}</span>
              <span v-if="relative.node">({{ relative.node.name }})</span>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </phi-page>
</template>

<script>
import app from "../store/app.js";
import PhiDrawer from "../components/Phi/Drawer.vue";
import qrcGenerator from "../components/Phi/QRCode/Generator.vue";

export default {
  name: "settings",
  components: { PhiDrawer, qrcGenerator },

  data() {
    return {
      app,
      card: {},
      advance: {},
      // !!! v3 specific
      course: null,
      getadvanceQR : false
    };
  },

  beforeRouteEnter(to, from, next) {
    let storageKey = `card:${app.user.id}`;
    let stored = JSON.parse(localStorage.getItem(storageKey));

    var that = null;

    if (stored) {
      
      next(vm => {

        that = vm;
        vm.card = stored.card;
        vm.course = stored.course;
      });
    }

    // Este request se hace SIEMPRE.   Si ya se ejecuto el next() en el IF anterior,
    // los llamados posteriores a next() se pueden ignorar con tranquilidad
    app.api
      .get(`/people/${app.user.id}/card`)
      .then(card => {
        // Extract node of rol "student" as course
        let course = null;
        for (let i = 0; i < card.roles.length; i++) {
          if (card.roles[i].role && card.roles[i].role.singular == "student") {
            course = card.roles[i].nodes[0].name;
            card.roles.splice(i, 1);
            break;
          }
        }

        localStorage.setItem(storageKey, JSON.stringify({ card, course }));

        // If next() was already called because a local value was found,
        // then update the current values with the freshly obtained ones
        if (that != null) {
          that.card = card;
          that.course = course;
          that.advance = card.qrData;
        }

        next(vm => {
          vm.card = card;
          vm.course = course;
        });
      })
      .catch(err => {
        if (stored == null) {
          throw err; // Request failed and no local data available
        }
      });

      this.getadvanceQR = this.$store.getters.getSetting("advanceQR")? this.$store.getters.getSetting("advanceQR"): false;
  },

  computed: { 
    qrdata() {

      if(this.$store.getters.getSetting("advanceQR")) {

        let gender = this.app.user.gender == "1"? "Masculino":"Femenino";
        let info   = "";

        if(this.advance.name) {
          info += "Nombres: "+this.advance.name+"\n";
        }

        if(this.advance.code) {
          info += "Codigo: "+this.advance.code+"\n";
        }

        if(this.advance.document) {
          info += this.advance.documentType+": "+this.advance.document+"\n";
        }

        if(this.advance.gender) {
          info += "Sexo: "+gender+"\n";
        }

        if(this.advance.mobile) {
          info += "Telefono: "+this.advance.mobile+"\n";
        }

        if(this.advance.birthDay) {
          info += "Fecha de Nacimiento: "+ this.advance.birthDay +"\n";
        }

        if(this.advance.blood) {
          info += "Tipo de sangre: "+this.advance.blood+"\n";
        }

        if(this.advance.weight) {
          info += "Peso: "+this.advance.weight+"\n";
        }

        if(this.advance.height) {
          info += "Estatura: "+this.advance.height+"\n";
        }       

        return info;
      }

      else {
        if(this.$store.getters.getSetting("qrMore"))
        {
          let gender = this.app.user.gender == "1"? "Masculino":"Femenino";
          let info   = "";

          info += "id: "+this.app.user.id+"\n";
          if(this.course != null)
          {
            info += "Curso: "+this.course+"\n";
          }      
          info += this.app.user.documentType+": "+ this.app.user.document+"\n";
          info += "Nombres "+this.app.user.firstName+"\n";
          info += "Apellidos: "+this.app.user.lastName+" "+this.app.user.lastName2+"\n";
          info += "Sexo: "+ gender +"\n";
          info += "Telefono: "+this.app.user.mobile+"\n"; 

          if(this.card.clinic)
          {
            info += "Tipo de sangre: "+ this.card.clinic.blood +"\n";
            info += "EPS: "+ this.card.clinic.field4 +"\n";
            info += "Peso: "+ this.card.clinic.weight +"\n";        
            info += "Estatura: "+ this.card.clinic.height +"\n"; 
          }     

          return info;
        }
        else{
          let organization = this.$store.getters.getSetting("organization");
          let qr_custom = organization && organization["qr"] ? organization["qr"] : null;
          
          return qr_custom ? this.app.user.document : this.app.user.id;        
        }
      }
    },

    enableQrdata() {
      return  this.$store.getters.getSetting("qrdata")? this.$store.getters.getSetting("qrdata"): false;
    },

    clinicFields() {
      return  this.$store.getters.getSetting("clinic_fields")? this.$store.getters.getSetting("clinic_fields"): false;
    },

    titleStyle() {
      return  this.$store.getters.getSetting("titleStyle")? this.$store.getters.getSetting("titleStyle"): "color: #fff;font-size: 20px; text-align:center;text-shadow: 0 1px 6px #000, 0 1px 3px #000;margin-bottom:10px;";
    }
  },
  methods: { 
    format_date(value){
      if (value) {

        var a = new Date(value * 1000);
        
        var year = a.getFullYear();
        var month = a.getMonth() + 1;
        var date = a.getDate();
        
        var time = date + '/' + month + '/' + year;
        return time;
      }
    },
  },
};
</script>

<style lang="scss">
.page-id {
  background-image: url("../assets/cover.jpg");

  .phi-page-contents {
    background-color: #fff !important;
  }

  .page-header {
    padding: 24px 0;
  }

  .page-toolbar button {
    color: #fff;
    text-shadow: 0 1px 6px #000, 0 1px 3px #000;
  }

  .details {
    position: relative;
    top: -60px;
    margin-bottom: 24px;

    .name {
      text-align: center;
      padding: 12px 0 0 0;
    }

    .avatar {
      margin: auto;

      border: 3px solid #f2f2f2;
      border-radius: 14px;
      background-color: #ccc;

      overflow: hidden;
      width: 120px;
      height: 140px;

      & > img {
        width: 100%;
        position: relative;
        top: -5%;
      }
    }

    
  }

  .fields {
    list-style: none;
    margin: 0;
    padding: 0;

    margin-bottom: 24px;

    li {
      text-align: center;
    }

    label {
      // display: block;
      font-size: 14px;
      color: #666;
    }
  }

  .roles {
    padding: 0 18px;

    .role {
      margin-bottom: 24px;

      label {
        display: block;
        font-size: 14px;
        color: #777;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>